import styles from "./available-popup.module.scss";
import Modal from "../../modal/modal";
import {FC, useEffect, useState} from "react";
import {IPopup, IShop} from "@/services/types/types";
import {getShops} from "@/utils/api/shops";
import {AvailablePopupStore} from "./available-popup-store/available-popup-store";
import {SkeletonLoader} from "@/components/skeleton-loader/skeleton-loader";

const avalibleShops = (store: any[], shops: any[]) => {
  const filtredShops: IShop[] = [];
  shops.forEach(element => {
    store.forEach((storeItem) => {
      const findStoreItem = element.items.find((elem: IShop) => {
        if (elem.value === storeItem.code) {
          elem.quantity = storeItem.amount
          filtredShops.push(elem)
        }
      })
    })
  });
  return filtredShops;
}

export const AvailablePopup: FC<IPopup> = (props) => {
  const {
    isOpen,
    onClose,
    store,
    warehouse,
  } = props;
  
  const [shopList, setShopList] = useState<IShop[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  
  useEffect(() => {
    if (!store) {
      setLoading(true);
      return;
    }
    getShops()
      .then((res) => {
        if (res.success) {
          const newShops = avalibleShops(store, res.items)
          setShopList(newShops)
          setLoading(false)
        }
      })
  }, [])
  
  const warehouseData = {
    title: 'Удаленный склад',
    value: '',
    schedule: '',
    quantity: '',
  }
  
  return (
    <Modal
      isOpen={isOpen}
      title={"Товар есть в этих магазинах:"}
      onClose={onClose}
      containerClassname={styles.modal_container}
    >
      {loading ? (
        <div className={styles.wrap}>
          <SkeletonLoader containerTag="div" height={122} width={`100%`} mb={4}/>
          <SkeletonLoader containerTag="div" height={122} width={`100%`} mb={4}/>
          <SkeletonLoader containerTag="div" height={122} width={`100%`} mb={4}/>
        </div>
      ) : (
        <div className={styles.wrap}>
          <ul className={styles.list}>
            {warehouse! > 0 && (
              // <li className={styles.item}>
              //   <p className={styles.text}>Удаленный склад</p>
              // </li>
              <AvailablePopupStore
                store={warehouseData}
                warehouse
                key={`${'Удаленный склад' + "liStoreModal" + "availablePopup"}`}
              />
            )}
            {shopList!
              // .sort((a, b) => a.name.localeCompare(b.name))
              .map((store, index: number) => {
                return (
                  <AvailablePopupStore
                    store={store}
                    key={`${index + "liStoreModal" + "availablePopup"}`}
                  />
                  // <li
                  //   key={`${index + "liStoreModal" + "availablePopup"}`}
                  //   className={styles.item}
                  // >
                  //   <Link href={`/shops/${store.value}/`} className={styles.link}>
                  //     {store.title}
                  //     <p className={styles.text}>в наличии {store.quantity} штук</p>
                  //   </Link>
                  // </li>
                );
              })}
          </ul>
        </div>
      )
      }
    </Modal>
  );
};
