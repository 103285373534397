import styles from "./create-ad-popup.module.scss";
import Modal from "../../modal/modal";
import InputComponent from "../../input/input";
import { FC, useEffect, useRef, useState } from "react";
import { IPopup } from "@/services/types/types";
import ButtonComponent from "@/components/button/button";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import { getUserInfo } from "@/services/redux/features/userSlice";
import { InputUploader } from "@/components/input-uploader/input-uploader";
import { SelectComponent } from "@/components/select/select";
import sortList from "./sortList.json";
import { checkUploadForm } from "@/utils/functions/check-upload-form";
import { editAnnouncement, postAnnouncement } from "@/utils/api/postAnnouncement";
import { callMessage } from "@/utils/functions/callMessage";
import { isEmptyObject } from "@/utils/functions/isEmptyObj";
import { SkeletonCreateAdPopup } from "@/components/skeleton-loader/create-ad-popup-skeleton/create-ad-popup-skeleton";
interface ITypeMaterial {
  [key: string]: string;
}
const typeMaterial: ITypeMaterial = {
  metallic: "Металл",
  tree: "Дерево",
  plastic: "Пластик",
};
interface ISortList {
  [key: string]: Array<{
    value: string;
    label: string;
  }>;
}
type IFormList = {
  elemType: string;
  id: string;
  label: string;
  type: string;
  maxLength: number;
  minLength: number;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  autoComplete: string;
  [key: string]: any;
  ad: any;
  isNew: boolean;
  loading: boolean;
};
export const CreateAdPopup: FC<IPopup> = (props) => {
  const {
    isOpen,
    onClose,
    ad = {},
    isNew = false,
    loading = false
  } = props;
  const user = useAppSelector(getUserInfo).info;
  const [imagesArray, setImagesArray] = useState([]) as any;
  const [initialAd, setInitialAd] = useState(ad);
  const [model, setModel] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [manufacturer, setManufacturer] = useState<string>("");
  const [weaponLength, setWeaponLength] = useState<string>("");
  const [weight, setWeight] = useState<string>("");
  const [price, setPrice] = useState<string>(ad.price || "");
  const [description, setDescriptio] = useState<string>("");
  const dispatch = useAppDispatch();
  const formRef = useRef<any>(null);
  const [typeData, setTypeData] = useState<string>("");
  const [sortData, setSortData] = useState<ISortList>(sortList);
  const [isLoading, setIsLoading] = useState(false);
  const [initalTypeState, setInitalTypeState] = useState<string | any[]>([{}]);
  const [initialCalibrState, setInitialCalibrState] = useState<string | any[]>([{}]);
  const [initialMaterialState, setInitialMaterialState] = useState<string | any[]>([{}]);
  const [initialConditionState, setInitialConditionState] = useState<string | any[]>([{}]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let data: any = Object.fromEntries(formData.entries());
    const newFormData = new FormData();
    data.files = imagesArray;
    let a = typeMaterial[data.material];
    data.material = a;
    Object.entries(data).forEach(([key, value]) => {
      newFormData.append(key, value as string);
    });
    imagesArray.forEach((file: any, i: number) => {
      newFormData.append(`files[${i}]`, file);
    });
    if (checkUploadForm(data)) {
      setIsLoading(true);
      if (isNew) {
        editAnnouncement(ad.id, newFormData)
          .then((res: any) => {
            setIsLoading(false);
            if (res && res.success) {
              callMessage(
                "Успешно!",
                `Ваше объявление отправлено на модерацию. После проверки оно появится в разделе "Комиссионное оружие" и в личном кабинете в разделе "Мои объявления"`,
                true
              );
              ym(42989679, "reachGoal", "advertisement");
              onClose();
            }
          })
          .catch(err => {
            setIsLoading(false);
          });
      } else {
        postAnnouncement(newFormData)
          .then((res: any) => {
            setIsLoading(false);
            if (res && res.success) {
              callMessage(
                "Успешно!",
                `Ваше объявление отправлено на модерацию. После проверки оно появится в разделе "Комиссионное оружие" и в личном кабинете в разделе "Мои объявления"`,
                true
              );
              ym(42989679, "reachGoal", "advertisement");
              onClose();
            }
          })
          .catch(err => {
            setIsLoading(false);
          });
      }
    }
  };

  const handleSelectChange = (e: { value: string }) => {
    setTypeData(e.value);
  };

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (!isNew && loading) {
      setShow(true);
      return;
    }
    if (isNew && loading) {
      setShow(loading);
      return;
    }
  }, [isNew, loading]);

  useEffect(() => {
    if (!loading) return;
    if (!isEmptyObject(ad)) {
      setInitalTypeState('');
      setInitialCalibrState('');
      setInitialMaterialState('');
      setInitialConditionState('');
      return;
    };
    const initalType = isEmptyObject(ad) ? sortList.type.filter(item => item.value === ad.type.value) : '';
    const initialCalibr = isEmptyObject(ad) ? sortList.smoothbore.filter(item => item.value === ad.caliber_other.value) : '';
    const initialMaterial = isEmptyObject(ad) ? sortList.material.filter(item => item.label === ad.priklad_other.value) : '';
    const initialCondition = isEmptyObject(ad) ? sortList.conditions.filter(item => item.value === ad.condition.value) : '';
    setInitalTypeState(initalType);
    setInitialCalibrState(initialCalibr);
    setInitialMaterialState(initialMaterial);
    setInitialConditionState(initialCondition);
    setImagesArray(ad.files)
    setModel(ad.model_other.value || '');
    setCity(ad.city.value || '');
    setManufacturer(ad.brand.value || '');
    setPrice(ad.price);
    setWeaponLength(ad.length.value || '');
    setWeight(ad.weight.value || '')
    setDescriptio(ad.comment.value || '')
  }, [ad, isNew, loading]);

  return (
    <Modal isOpen={isOpen} title={"Новое объявление"} onClose={onClose}>
      <div className={styles.wrap}>
        <form className={styles.form} onSubmit={handleSubmit} ref={formRef}>
          {!show ? (
            <SkeletonCreateAdPopup />
          ) : (
            <>
              <div className={styles.inputList}>
                <InputComponent
                  id="model-input"
                  label={"Модель"}
                  className={styles.inputWrap}
                >
                  <input
                    id="model-input"
                    type="text"
                    maxLength={20}
                    minLength={2}
                    className={styles.input}
                    placeholder=" "
                    value={model}
                    onChange={(e: any) => setModel(e.target.value)}
                    name="model"
                    autoComplete="off"
                  />
                </InputComponent>
                <InputComponent
                  id={"city-input"}
                  label="Город"
                  className={styles.inputWrap}
                >
                  <input
                    id={"city-input"}
                    type="text"
                    maxLength={40}
                    minLength={2}
                    className={styles.input}
                    placeholder=" "
                    value={city}
                    onChange={(e: any) => setCity(e.target.value)}
                    name="city"
                    autoComplete="off"
                  />
                </InputComponent>
                <SelectComponent
                  isSearchable={false}
                  defaultValue={initalTypeState[0]}
                  isChoosed={initalTypeState[0]}
                  options={sortData.type}
                  name="type"
                  className={styles.select}
                  isInForm={true}
                  placeholder={"Вид оружия"}
                  onChange={handleSelectChange}
                />
                <SelectComponent
                  isSearchable={false}
                  defaultValue={initialCalibrState[0]}
                  isChoosed={initialCalibrState[0]}
                  options={!!typeData ? sortData[typeData] : sortData.smoothbore}
                  name={"calibr"}
                  className={styles.select}
                  isInForm={true}
                  placeholder={"Калибр"}
                />
                <SelectComponent
                  isSearchable={false}
                  defaultValue={initialMaterialState[0]}
                  isChoosed={initialMaterialState[0]}
                  options={sortData.material}
                  name={"material"}
                  className={styles.select}
                  isInForm={true}
                  placeholder={"Материал приклада"}
                />
                <InputComponent
                  id="manufacturer-input"
                  label="Производитель"
                  className={styles.inputWrap}
                >
                  <input
                    id="manufacturer-input"
                    type="text"
                    maxLength={40}
                    minLength={2}
                    className={styles.input}
                    placeholder=" "
                    value={manufacturer}
                    onChange={(e: any) => setManufacturer(e.target.value)}
                    name="manufacturer"
                    autoComplete="off"
                  />
                </InputComponent>
                <InputComponent
                  id="weaponLength-input"
                  label="Длина оружия"
                  className={styles.inputWrap}
                >
                  <input
                    id="weaponLength-input"
                    type="text"
                    maxLength={40}
                    minLength={2}
                    className={styles.input}
                    placeholder=" "
                    value={weaponLength}
                    onChange={(e: any) => setWeaponLength(e.target.value)}
                    name="length"
                    autoComplete="off"
                  />
                </InputComponent>
                <InputComponent
                  id="price-input"
                  label="Цена"
                  className={styles.inputWrap}
                >
                  <input
                    id="price-input"
                    type="number"
                    maxLength={40}
                    minLength={2}
                    className={styles.input}
                    placeholder=" "
                    value={price}
                    onChange={(e: any) => setPrice(e.target.value)}
                    name="price"
                    autoComplete="off"
                  />
                </InputComponent>
                <SelectComponent
                  isSearchable={false}
                  defaultValue={initialConditionState[0]}
                  isChoosed={initialConditionState[0]}
                  options={sortData.conditions}
                  name={"condition"}
                  className={styles.select}
                  isInForm={true}
                  placeholder={"Состояние"}
                />
                <InputComponent
                  id="weight-input"
                  label="Вес"
                  className={styles.inputWrap}
                >
                  <input
                    id="weight-input"
                    type="number"
                    maxLength={40}
                    minLength={2}
                    className={styles.input}
                    placeholder=" "
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    name="weight"
                    autoComplete="off"
                  />
                </InputComponent>
              </div>
              <div className={styles.textareaWrap}>
                <textarea
                  className={`${styles.textarea} ${description.length < 20 && styles.textarea_invalid
                    }`}
                  name="description"
                  placeholder="Добавьте описание"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescriptio(e.target.value)}
                ></textarea>
              </div>
              <InputUploader
                imagesArray={imagesArray}
                setImagesArray={setImagesArray}
              />
              <ButtonComponent
                type="submit"
                className={styles.button}
                disabled={isLoading}
              >
                <>Разместить объявление</>
              </ButtonComponent>
            </>
          )}
        </form>
      </div>
    </Modal>
  );
};
